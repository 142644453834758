import React from "react";
import { styled } from "../../../stitches.config";
import { Layout } from "../../layout";
import { Colors, Hero } from "../../components/shared/Hero";
import { Button } from "../../components/shared/Button";
import MainImage from "../../images/monitoring/monitoring-image.svg";
import SubImage from "../../images/monitoring/monitoring-sub-image.svg";
import { Link, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { BreadCrumbs } from "../../components/shared/BreadCrumbs";
import { routes } from "../../routes/routes";
import { Seo } from "../../components/shared/Seo";

function Monitoring() {
  const { t } = useTranslation();
  const { navigate } = useI18next();
  const bgColor = Colors.transparent;

  return (
    <Layout>
      <Seo
        title={t("monitoring.title")}
        description={t("monitoring.text1")}
        image={MainImage}
      />
      <Hero pic={{ wrap: Image, src: MainImage }} backgroundColor={bgColor}>
        <BreadCrumbs
          root={{ link: routes.tbizedi.edi, title: "tbizedi" }}
          current={"tbizediSubs.monitoring"}
        />
        <Heading color={bgColor}>{t("monitoring.title")}</Heading>
        <HeroDesc color={bgColor}>
          {t("monitoring.text1")}
          <TextBold>{t("monitoring.text1Bold")}</TextBold>
          <StyledLink to={routes.tbizedi.baseData}>
            {t("monitoring.text1Link")}
          </StyledLink>
          <TextBold>{t("monitoring.text1BoldEnd")}</TextBold>
        </HeroDesc>
        <HeroDesc color={bgColor}>{t("monitoring.text2")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("monitoring.text3")}</HeroDesc>
        <HeroDesc color={bgColor}>{t("monitoring.text4")}</HeroDesc>
      </Hero>
      <Container>
        <SecondImage src={SubImage} />
        <Box>
          <Text color={bgColor}>
            {t("monitoring.sub.textMain")}
            <StyledLink to={routes.tbizedi.screenDisplay}>
              {t("monitoring.sub.textLink")}
            </StyledLink>
            {t("monitoring.sub.textEnd")}
          </Text>
          <Text color={bgColor}>{t("monitoring.sub.text1")}</Text>
          <ButtonsWrap>
            <Button onClick={() => navigate(routes.contact.contactForm)}>
              {t("eDataGatewayContactUs")}
            </Button>
          </ButtonsWrap>
        </Box>
      </Container>
    </Layout>
  );
}

export default Monitoring;

const Container = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  background: "$secondaryBackground",
  gap: 68,
  padding: "60px 16px",
  maxWidth: "1280px",
  margin: "48px auto 0",
  "@md": {
    background: "$white",
    flexDirection: "row",
    gap: 214,
    margin: "0 auto",
    padding: "60px 0 92px",
  },
});

const Box = styled("div", {});

const Text = styled("p", {
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  color: "$screedDisplayText",
  margin: "0 0 12px",
  "&:last-of-type": {
    margin: "0 0 32px",
  },
});

const TextBold = styled("p", {
  fontSize: "17px",
  fontStyle: "italic",
  fontWeight: 600,
  lineHeight: "24px",
  color: "$text",
  margin: "0 0 12px",
  display: "inline",
});

const StyledLink = styled(Link, {
  fontSize: "17px",
  fontStyle: "italic",
  cursor: "pointer",
  fontWeight: 600,
  lineHeight: "24px",
  color: "$accent",
  margin: "0 0 12px",
  textDecoration: "none",
});

const Image = styled("img", {
  maxWidth: "506px",
  width: "90%",
  objectFit: "contain",
  "@md": {
    marginTop: "78px",
  },
});

const SecondImage = styled("img", {
  objectFit: "contain",
  maxWidth: "353px",
  marginTop: "58px",
  width: "90%",
  "@md": {
    marginTop: "0",
  },
});

const Heading = styled("h1", {
  color: "$white",
  fontSize: "32px",
  fontWeight: 700,
  lineHeight: "40px",
  margin: "30px 0 0",

  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },

  "@md": {
    margin: "0",
    fontSize: "48px",
    lineHeight: "56px",
    color: "$heading",
  },
});

const HeroDesc = styled("p", {
  color: "$white",
  fontSize: "17px",
  fontWeight: 400,
  lineHeight: "24px",
  margin: "12px 0 0",
  variants: {
    color: {
      transparent: {
        color: "$text",
      },
    },
  },
  "&:last-child": {
    margin: "12px 0 32px",
  },
  "@md": {
    color: "$text",
  },
});

const ButtonsWrap = styled("div", {
  display: "flex",
  flexDirection: "column",
  maxWidth: "400px",
  margin: "32px auto 0",
  "@md": {
    flexDirection: "row",
    margin: 0,
  },
});
